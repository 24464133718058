import { ApplicationController, useDebounce } from 'stimulus-use'
import Fuse from 'fuse.js'

export default class extends ApplicationController {
  static debounces = [{name: '_addToDataLayer', wait: 3000}]

  static targets = ["input", "searchbarResults", "searchbarWebsiteSearchResults", "searchbarWebsiteItems", "searchbarKnowledgeBaseResults", "searchbarKnowledgeBaseItems", "searchbarKnowledgeBaseError", "searchbarKnowledgeBaseLink", "searchbarNoResults"]

  connect() {
    useDebounce(this, {wait: 300})

    document.addEventListener("click",  (e) => {
      let parentNode = e.target;
      while (parentNode) {
        if (parentNode === this.searchbarResultsTarget || parentNode === this.inputTarget)
          return;

        parentNode = parentNode.parentNode;
      }
      this._showSearchResults(false);
    });

    // set the focus to the input text when the search bar is active
    document.addEventListener("searchbarActivated", (e) => {
      this.inputTarget.focus();
    });
  }

  async search(event) {
    const searchTerm = this.inputTarget.value.toLowerCase();

    this._showSearchResults(true);

    // hide results when there is nothing to see
    if (!searchTerm) return this._showSearchResults(false);

    const results = await Promise.all([
      this._searchWebsite(searchTerm),
      this._searchKnowledgeBase(searchTerm),
    ]);

    const searchResultsCount = results.reduce((acc, {count}) => acc + count, 0);

    this.searchbarNoResultsTarget.style.display = searchResultsCount > 0 ? "none" : "block";
    this._addToDataLayer(searchTerm, searchResultsCount);
  }

  async _getSearchData() {
    if (this.#fuse) return this.#fuse;
    const base_url = window.location.origin;
    const search_index_location = base_url + "/assets/search_index.json";
    const search_data = await fetch(search_index_location).then((response) => response.json());
    this.#fuse = new Fuse(search_data, {
      keys: [{ name: 'title', weight: 5 }, { name: 'description', weight: 3 }, { name: 'content', weight: 1 }],
      includeScore: true,
      minMatchCharLength: 2,
      distance: 155,
      threshold: 0.3,
      // We don't want to sort the results, because we want to sort manually to deprioritize some items
      shouldSort: false,
    });
    return this.#fuse;
  }

  async _searchWebsite(searchTerm) {
    const fuse = await this._getSearchData();
    const result = fuse.search(searchTerm);

    // We want to deprioritize items of type 'blog', 'experience', 'city', 'expert'
    const deprioritizedTypes = ['blog', 'experience', 'city', 'expert'];
    const sortedResult = result.sort((a, b) => {
      if (deprioritizedTypes.includes(a.item.type) && deprioritizedTypes.includes(b.item.type)) return a.score - b.score;
      if (deprioritizedTypes.includes(a.item.type)) return 1;
      if (deprioritizedTypes.includes(b.item.type)) return -1;
      // We want to prioritize items with a higher score by default
      return a.score - b.score;
    });
    const searchResults = sortedResult.slice(0, 5);

    // construct HTML elements
    this._createSearchResults(searchResults, searchTerm, this.searchbarWebsiteItemsTarget);

    this.searchbarWebsiteSearchResultsTarget.style.display = searchResults.length > 0 ? 'block' : 'none';

    return {count: searchResults.length};
  }

  async _searchKnowledgeBase(searchTerm, resolve) {
    const base_url = 'https://help.moneybird.nl';
    try {
      const response = await fetch(
        `${base_url}/support/search/solutions.json?term=${searchTerm}&max_matches=5&need_count=true`,
        {cache: 'force-cache', headers: {'Content-Type': 'application/json'}}
      );

      const result = await response.json();
      const items = result.item.map((item) => ({
        item: {type: 'kennisbank', title: item.title, url: `${base_url}${item.url}`, description: item.desc}
      }));
      this._createSearchResults(items, searchTerm, this.searchbarKnowledgeBaseItemsTarget);
      if (result.count > 5) {
        this.searchbarKnowledgeBaseLinkTarget.href = `${base_url}/support/search?term=${searchTerm}`;
        this.searchbarKnowledgeBaseLinkTarget.querySelector('span').innerText = result.count - 5;
        this.searchbarKnowledgeBaseLinkTarget.classList.remove('hidden');
      } else {
        this.searchbarKnowledgeBaseLinkTarget.classList.add('hidden');
      }
      this.searchbarKnowledgeBaseResultsTarget.style.display = result.count > 0 ? 'block' : 'none';
      this.searchbarKnowledgeBaseErrorTarget.classList.add('hidden');
      return {count: result.count};
    } catch (error) {
      this.searchbarKnowledgeBaseResultsTarget.style.display = 'block';
      this.searchbarKnowledgeBaseErrorTarget.classList.remove('hidden');
      return {count: 0};
    }
  }

  _createSearchResults(searchResults, searchTerm, targetElement) {
    this._clearSearchResults(targetElement)
    searchResults.forEach(searchResult => {
      this._createSearchResultElement(searchResult, searchTerm, targetElement)
    });
  }

  _createSearchResultElement(searchResult, searchTerm, targetElement) {
    const searchResultItem = document.createElement("a");
    searchResultItem.setAttribute("href", searchResult.item["url"]);
    // const iconElement = document.createElement("div");
    // iconElement.classList.add("icon");
    // iconElement.innerHTML = "<i class='" + this._iconByType(searchResult.item["type"]) + "'></i>";
    const entryElement = document.createElement("div");

    entryElement.classList.add("entry");
    entryElement.innerHTML = "<span class='text-dark-blue/80 text-sm'>" + this._mark(searchResult.item["title"], searchTerm) + "</span>";

    if (searchResult.item["description"]) {
      entryElement.innerHTML += "<span class='summary'>" + this._mark(searchResult.item["description"], searchTerm) + "</span>";
    }

    // searchResultItem.appendChild(iconElement);
    searchResultItem.appendChild(entryElement);

    targetElement.appendChild(searchResultItem);
  }

  select(keydownEvent) {
    let x = this.searchbarResultsTarget;
    if (x) x = x.getElementsByTagName("a");
    if (keydownEvent.keyCode === 40) {
      this.#currentSelection++;
      this._setSelection(x);
    } else if (keydownEvent.keyCode === 38) { //up
      this.#currentSelection--;
      this._setSelection(x);
    } else if (keydownEvent.keyCode === 13) { //enter
      keydownEvent.preventDefault();
      if (this.#currentSelection > -1) {
        if (x) x[this.#currentSelection].click();
      }
    }
  }

  // PRIVATE
  #search_data;
  #fuse;
  #currentSelection = -1;

  _setSelection(x) {
    if (!x || x.length === 0) return false;

    // remove selections
    for (let i = 0; i < x.length; i++) {
      x[i].classList.remove("selected");
    }

    // cycle
    if (this.#currentSelection >= x.length) this.#currentSelection = 0;
    if (this.#currentSelection < 0) this.#currentSelection = (x.length - 1);

    x[this.#currentSelection].classList.add("selected");
  }

  _showSearchResults(enable) {
    this.searchbarResultsTarget.classList.toggle("opacity-0", !enable);

    if (window.innerWidth > 1024) return;
    const shadowRoot = document.querySelector('menu-component')?.shadowRoot;
    if (shadowRoot) {
      const menubar = shadowRoot.querySelector('.menu-bar');
      menubar.classList.toggle("searchbar-active", enable);
    }
  }

  _clearSearchResults(targetElement) {
    // remove all search result elements
    targetElement.innerHTML = "";

    // nothing to select anymore
    this.#currentSelection = -1;
  }

  _mark(textToMark, mark) {
    const re = new RegExp(mark, 'gi');
    return textToMark.replace(re, "<mark>" + mark + "</mark>");
  }

  _iconByType(type) {
    if (type === "article") return "far fa-book";
    if (type === "blog") return "far fa-newspaper";
    if (type === "city") return "far fa-house-building";
    if (type === "experience") return "far fa-message-lines";
    if (type === "kennisbank") return "far fa-books";
    return "far fa-memo-pad";
  }

  _addToDataLayer(searchTerm, searchResultsCount) {
    if (!searchTerm) return;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'search',
      'search_term': searchTerm,
      'search_results_count': searchResultsCount,
    });
  }
}
