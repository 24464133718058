import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "demio", "error" ];

  #demioObserver
  #demioTargetHasForm

  connect() {
    this._setupDemio();
    setTimeout(this._setVisibility(), 5000);
  }

  _setupDemio() {
    this.#demioObserver = new MutationObserver(() => this._updateDemioLoaded());
    this.#demioObserver.observe(this.demioTarget, {
      childList: true
    });
    this._updateDemioLoaded();
  }

  _setVisibility() {
    this.errorTarget.classList.toggle("hidden", this.#demioTargetHasForm);
    this.demioTarget.classList.toggle("hidden", !this.#demioTargetHasForm);
  }

  _updateDemioLoaded() {
    this.#demioTargetHasForm = document.querySelectorAll('[data-live-sessions-target="demio"]')[0].innerHTML.includes('form');

    if (this.#demioTargetHasForm) {
      this._setVisibility();
      this.#demioObserver.disconnect();
    }
  }
}
