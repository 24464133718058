import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["adviserList", "adviser", "searchBar", "map", "noAdvisers", "input"]

  #map;
  #markerList;

  connect() {
    this.element.addEventListener("map:onload", (e) => {
        this.#map = e.detail.map;
        this.#markerList = [];
        this._loadLocations();
        this._addIdleListener();
    });

    this.element.addEventListener("map:markers_updated", (e) => {
      this.#markerList = e.detail.markerList;
    });

    this.element.addEventListener("search:place_changed", (e) => {
      this.mapTarget.dispatchEvent(
        new CustomEvent('map:move', { bubbles: true, detail: { location: e.detail.location, zoom: 11} })
      );
    });
  }

  reset() {
    this.mapTarget.dispatchEvent(
      new CustomEvent('map:reset', { bubbles: true })
    );

    this.searchBarTarget.dispatchEvent(
      new CustomEvent('search:reset', { bubbles: true })
    );
  }

  _loadLocations() {
    fetch('/experts/locations.json')
    .then(res => res.json())
    .then((json) => {
      this.mapTarget.dispatchEvent(
        new CustomEvent('map:add_markers', { bubbles: true, detail: { markerList: json.locations } })
      );
    })
    .catch
  }

  _finishSearch(resultsCount) {
    this.searchBarTarget.dispatchEvent(
      new CustomEvent('search:finished', { bubbles: true, detail: { resultsCount: resultsCount } })
    );
  }

  _addIdleListener() {
    this.#map.addListener("idle", () => {
      this.adviserTargets.forEach(adviser => adviser.classList.add("hidden"));
      const visibleMarkers = this._getMarkersWithinBounds();
      this._updateAdviserList(visibleMarkers);
    });
  }

  _updateAdviserList(visibleMarkers) {
    this._updateList(visibleMarkers);
    this.noAdvisersTarget.classList.toggle("hidden", visibleMarkers.length > 0);
    this._finishSearch(visibleMarkers.length);
  }

  _updateList(visibleMarkers) {
    const inputCity = this.inputTarget.value.split(",")[0];

    visibleMarkers.forEach((adviser) => {
      document.querySelectorAll(`[data-place-id*="${ adviser.place_id }"]`).forEach(e => {
        if (adviser.city == inputCity) {
          this.adviserListTarget.removeChild(e);
          this.adviserListTarget.insertBefore(e, this.adviserListTarget.firstChild)
        }
        e.classList.remove("hidden")
      })
    })
  }

  _getMarkersWithinBounds() {
    const bounds = this.#map.getBounds();
    const filteredList = [];
    (this.#markerList || []).forEach(marker => {
      if (bounds.contains({lat: marker.position.lat(), lng: marker.position.lng()})) filteredList.push(marker);
    })

    return filteredList
  }
}
