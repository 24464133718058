import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  expandCookieView() {
    const componentRoot = document.querySelector("mb-cookie-modal-component");
    const shadowRoot = componentRoot.shadowRoot;

    const root = shadowRoot.getElementById("mb-cookie-modal");
    root.showModal();
    shadowRoot.getElementById("freshworks_cookies").style.display = "flex"
  }
}
